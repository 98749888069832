import React, { useState, useContext, Component, createRef } from "react";
import { Container, Row, Button, Col, Nav, Table, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCurrentDateTime } from "../../helper";
import CartContext from "./CartContext";
import { OrderSummary, OrderedItems } from "./CheckoutView";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Country, State, City } from "country-state-city";

export default class CompleteOrderView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      intervalId: "",
      timerExpired: false,
      firstLoad: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      if (this.context.portalMode == "Kiosk") {
        this.state.intervalId = setInterval(
          () => {
            this.setState({ timerExpired: true });
          },
          this.context.storeSettings.kioskInvoicePageWaitTimeInMilliSeconds ==
            undefined
            ? 10000
            : this.context.storeSettings.kioskInvoicePageWaitTimeInMilliSeconds
        ); //refresh once in 10secs
      }
    }
    var d1 = document.getElementById("one");
    if (d1) {
      d1.innerHTML =
        "<html>" +
        this.context.storeSettings.invoiceCompleteOrderMessage +
        "</html>";
    }
  }
  componentWillUnmount() {
    if (this.context.OrderStatus === "Success")
      this.context.ClearCartAndOrderDetails();
  }
  onclickCheckCheckoutLink = () => {
    this.context.UpdateOrderStatus("");
  };
  IamdoneClick = (e) => {
    clearInterval(this.state.intervalId);
    window.close();
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;

    // for kiosk
    if (this.state.timerExpired == true) {
      clearInterval(this.state.intervalId);
      window.close();
      //return <Navigate to="/Kiosk" />;
    }

    if (this.context.OrderStatus === "Dismissed") {
      return (
        <>
          <div className="text-start mx-5">
            <br></br>
            <Button
              as={Link}
              className="text-primary bg-white text-center border-0"
              to="/Checkout"
              onClick={(e) => this.context.UpdateOrderStatus("")}
            >
              <h5>
                &#60;&#60; Order dismissed. Go back to CheckOut page and try
                again!
              </h5>
            </Button>
            <br></br>
            {/* <h2 className="text-danger">
              <span className="blink_me">
                Payment dismissed. Please try again &nbsp;&nbsp;
              </span>
              <Button size="lg" onClick={(e) => this.CallRazorPay()}>
                <b>&nbsp;&nbsp;Pay&nbsp;&nbsp;</b>
              </Button>
            </h2> */}
          </div>
        </>
      );
    }
    if (this.context.OrderStatus === "Failed") {
      return (
        <>
          <div className="text-start mx-5">
            <br></br>
            <Button
              as={Link}
              className="text-primary bg-white text-center border-0"
              to="/Checkout"
              onClick={(e) => this.context.UpdateOrderStatus("")}
            >
              <h5>&#60;&#60; Go back to CheckOut page</h5>
            </Button>
            <br></br>
            <h2 className="text-danger">
              <span className="blink_me">
                Order Failed. Please try again! &nbsp;&nbsp;
              </span>
            </h2>
          </div>
        </>
      );
    }
    if (this.context.OrderStatus === "Success") {
      return (
        <>
          <br></br>
          <div className="row mt-1 mx-1 px-0">
            <div className="col-md-2"></div>
            <div className="col-md-8 bg-white mb-5 invoiceDsnSec">
              <Row className="text-center">
                <Col className=" text-center ">
                  <h1 className="mb-3 mt-3">
                    <b>Invoice</b>
                  </h1>
                </Col>
              </Row>
              <Row className="text-center mb-4">
                <div>
                  {this.context.storeSettings.storeName},{" "}
                  {this.context.shippingDetails.address},{" "}
                  {this.context.shippingDetails.city},{" "}
                  {this.context.shippingDetails.state},{" "}
                  {/* {this.context.shippingDetails.country}, */}
                  {this.context.GetCountryByCode(
                    this.context.shippingDetails.country
                  )}
                  , {this.context.shippingDetails.pincode}
                </div>
                {this.context.storeSettings.gstNumber ? (
                  <>
                    <div>GSTIN: {this.context.storeSettings.gstNumber}</div>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row className="">
                <Col className="text-start ms-4 text-success">
                  <h5 class="text-dark ">
                    Order No:{" "}
                    <b>
                      {/* {this.context.orderData.orderId.replace(
                    this.context.store.id,
                    ""
                  )} */}
                      {this.context.orderData.orderId}
                    </b>
                  </h5>
                </Col>
                <Col className="text-end me-4 text-dark">
                  {/* <h3>{this.context.storeSettings.storeName}</h3> */}
                  <h5 class="text-dark ">
                    {" "}
                    Date:
                    {/* {new Date().toLocaleString(
                  this.context.storeSettings.defaultLocale
                )} */}
                    <b>{this.context.GetInDateFormat(new Date())}</b>
                  </h5>
                </Col>
              </Row>

              <div className="mx-3 px-3 text-start">
                <br></br>
                <h4 className="text-success text-center invoiceSuccessMsgDsn mb-3">
                  <span>
                    {/* <img
                  src="./images/smiley.jpg"
                  className="mx-2"
                  alt="..."
                  width="40"
                  height="40"
                /> */}
                    {this.context.portalMode == "Kiosk" ? (
                      <>
                        {
                          this.context.storeSettings
                            .kioskInvoiceCompleteOrderMessage
                        }
                      </>
                    ) : (
                      <>
                        <div id="one"></div>
                        {/* {this.context.storeSettings.invoiceCompleteOrderMessage} */}
                      </>
                    )}
                  </span>
                </h4>
                <div className="text-center">
                  {this.context.portalMode == "Kiosk" ? (
                    <>
                      <Button
                        size="lg"
                        onClick={(e) => this.IamdoneClick(e)}
                        style={{
                          fontFamily: "sans-serif",
                          backgroundColor:
                            this.context.theme.mainNavBarBackgroundColor,
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                      >
                        <h2>I'm done!</h2>
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <></>
                {/* Bank Receipt */}
                <div className="p-2 m-0">
                  {this.context.orderData.paymentModeId == 9 ? (
                    <>
                      <div className="border paymentDetailDsn">
                        {
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                this.context.storeSettings
                                  .bankReceiptDescriptionHtml,
                            }}
                          />
                        }
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <CustomerOrderSummary
                  order={this.context.orderData}
                ></CustomerOrderSummary>
                <br></br>
                <h4 className="text-dark text-center mt-4 mb-3 ">
                  <b>Ordered Items</b>
                </h4>
                <CustomerOrderedItems
                  orderedItems={this.context.orderData.customerOrderedItems}
                ></CustomerOrderedItems>
                <br></br>
                <br></br>
                <CustomerDetails></CustomerDetails>
                <br></br>
                <br></br>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </>
      );
    } else if (this.state.OrderStatus === "Failed") {
      return (
        <>
          <h1> Payment Failed. Please try again</h1>
        </>
      );
    }
  }
}

export class CustomerOrderSummary extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  GetTotalSavings = () => {
    var totsav = 0;
    this.props.order.customerOrderedItems.map((item) => {
      totsav =
        totsav +
        Math.round(
          (Number(item.mrp) * Number(item.qty) * Number(item.discount)) / 100,
          0
        );
    });
    return totsav;
  };

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Order Summary */}

        <Row border-dark>
          <h4 className=" text-center text-dark mt-4 mb-3">
            <b>Order Summary</b>
          </h4>
        </Row>
        <div className="mx-3 px-1 border ">
          <Container className="px-0 bg-white">
            <br></br>
            <Row className="py-1 ">
              <Col xs={1}></Col>
              <Col className=" text-start">
                <Form.Label size="" className="">
                  <b>
                    Subtotal [
                    {this.context.getTotalQtyInCart(
                      this.props.order.customerOrderedItems
                    )}{" "}
                    Items]:
                  </b>
                </Form.Label>
              </Col>
              <Col xs={3} className="text-end">
                <Form.Label size="" className="">
                  <h5>
                    {this.props.order.subTotalAmt.toLocaleString(
                      this.context.storeSettings.defaultLocale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: this.context.storeSettings.defaultCurrency,
                      }
                    )}
                  </h5>
                </Form.Label>
              </Col>
              <Col xs={3}></Col>
            </Row>
            <Row className="py-1">
              <Col xs={1}></Col>
              <Col className=" text-start">
                <Form.Label size="" className="">
                  <b>Delivery/Shipping charges :</b>
                </Form.Label>
              </Col>
              <Col xs={3} className="text-end">
                <Form.Label size="" className="">
                  <h5>
                    {Math.round(this.props.order.deliveryAmt, 0).toLocaleString(
                      this.context.storeSettings.defaultLocale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: this.context.storeSettings.defaultCurrency,
                      }
                    )}
                  </h5>
                </Form.Label>
              </Col>
              <Col xs={3}></Col>
            </Row>
            {this.context.storeSettings.hideCouponCodeField == true ? (
              ""
            ) : (
              <>
                {this.props.order.couponValue > 0 ? (
                  <>
                    <Row className="py-1 ">
                      <Col xs={1}></Col>
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          <b>Coupon Code:</b>
                        </Form.Label>
                      </Col>
                      <Col xs={3} className="text-end">
                        {this.props.order.couponCode}
                      </Col>
                      <Col xs={3}></Col>
                    </Row>
                    <Row className="py-1">
                      <Col xs={1}></Col>
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          <b>Coupon Code value :</b>
                        </Form.Label>
                      </Col>
                      <Col xs={3} className="text-end">
                        <Form.Label size="" className="">
                          <h5>
                            {this.props.order.couponValue.toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </h5>
                        </Form.Label>
                      </Col>
                      <Col xs={3}></Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {this.context.taxDetails.inclusiveOfTax == true ? (
              ""
            ) : (
              <>
                <Row className="py-1">
                  <Col xs={1}></Col>
                  <Col className=" text-start">
                    <Form.Label size="" className="">
                      <b>
                        Tax&nbsp; ({this.context.taxDetails.taxPercentage}
                        %) :
                      </b>
                    </Form.Label>
                  </Col>
                  <Col xs={3} className="text-end">
                    <Form.Label size="" className="">
                      <h5>
                        {this.props.order.taxAmount.toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </h5>
                    </Form.Label>
                  </Col>
                  <Col xs={3}></Col>
                </Row>
              </>
            )}
            <Row className="mtBorDsn mx-0">
              <Col xs={1}></Col>
              <Col className=" text-start">
                <Form.Label size="" className="">
                  <h3 className="my-3"> Total :</h3>
                </Form.Label>
              </Col>
              <Col xs={3} className="text-end">
                <Form.Label size="" className="my-3">
                  <h3>
                    <b>
                      {Math.round(this.props.order.totalAmt, 0).toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                    </b>
                  </h3>
                  Savings:
                  {this.props.order.totalSavings.toLocaleString(
                    this.context.storeSettings.defaultLocale,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    }
                  )}
                </Form.Label>
              </Col>
              <Col xs={3}></Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export class CustomerOrderedItems extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="mx-3 p-3 bg-light border ">
          <div className="mx-0 px-0">
            <div className="row mx-0 px-0 ">
              <div className="px-3 ">
                <Table
                  border
                  className="cartviewtableOnCheckout  px-5"
                  responsive="md"
                >
                  <thead>
                    <tr className="bg-light text-center">
                      <th className=""></th>
                      <th className=""></th>
                      <th>Price</th>
                      <th>Discount %</th>
                      <th>Qty</th>
                      <th>Sub.Total</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {this.props.orderedItems.map((ordereditem) => (
                      <>
                        <tr className="text-center align-middle">
                          <td className="col-md-1">
                            <img
                              src={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/images/" +
                                ordereditem.imgFileName +
                                this.context.store.storageSasToken
                              }
                              className="cardprodimg-on-cartview mx-auto"
                              alt="..."
                            />
                          </td>
                          <td className="col-md-2 ">
                            <strong>{ordereditem.productName}</strong>
                          </td>
                          <td className="col-md-2 ">
                            {ordereditem.mrp.toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </td>
                          <td className="col-md-2 ">{ordereditem.discount}%</td>
                          <td className="col-md-2 ">{ordereditem.qty}</td>
                          <td className="col-md-2">
                            <b>
                              {Math.round(
                                ordereditem.qty *
                                  (ordereditem.mrp -
                                    (ordereditem.mrp * ordereditem.discount) /
                                      100),
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </b>
                            <br />
                            Savings:{" "}
                            {Math.round(
                              ordereditem.qty *
                                ((ordereditem.mrp * ordereditem.discount) /
                                  100),
                              0
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export class CustomerDetails extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="mx-3 px-1 border p-4 ">
          <div className="row mt-1 mx-1 px-0">
            <Container className="bg-white ">
              <Row border-dark>
                <h4 className=" text-start mb-4">
                  <strong>
                    <b>Customer Information</b>
                  </strong>
                </h4>
                <br></br>
              </Row>
              <Row>
                <div className="col-md-6 ">
                  <h5 className=" text-start text-dark">
                    <strong>Delivery address</strong>
                  </h5>
                  <h5>
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .firstName
                    }
                    &nbsp;{" "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .lastName
                    }
                    <br></br>
                    Phone No :{" "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .mobileNo
                    }
                    <br></br>
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .address
                    }
                    <br></br>
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .city
                    }
                    ,{" "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .state
                    }
                    ,{" "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .country
                    }
                    {" - "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .pinCode
                    }
                  </h5>
                  <br />
                  <h5 className=" text-start text-dark">
                    <strong>Delivery Note:</strong>
                    {this.context.shippingDetails.deliveryDuration}
                  </h5>
                </div>
                {this.context.userData.customerUserTypeId == 1 ? (
                  <>
                    <div className="col-md-6 ">
                      <h5 className=" text-start text-dark">
                        <strong>Billing address</strong>
                      </h5>
                      <h5>
                        {this.context.userData.firstName}
                        &nbsp; {this.context.userData.lastName}
                        <br></br>
                        Phone No : {this.context.userData.mobileNo}
                        <br></br>
                        {this.context.userData.address}
                        <br></br>
                        {this.context.userData.city},{" "}
                        {this.context.userData.state},{" "}
                        {this.context.userData.country}
                        {" - "}
                        {this.context.userData.pinCode}{" "}
                      </h5>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export class CompleteOrderKioskPhonePe extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.context.SetPortalMode("Kiosk");
  };
  render() {
    return (
      <>
        <CompleteOrderPhonePe></CompleteOrderPhonePe>
      </>
    );
  }
}

export class CompleteOrderPhonePe extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      // maincategorynamearg: "",
      // maincategorynameargloaded: false,
      merchantTransactionId: "",
      Status: "",
      receivedArg: "",
    };
  }

  GetOrdereByTransactionId = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetOrdereByPhonePeTransactionId/" +
          this.state.merchantTransactionId
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.context.storeOrderInformation(response.data);
            this.context.UpdateOrderStatus("Success");
            this.setState({ Status: "Success" });
          } else {
            this.context.UpdateOrderStatus("Failed");
            this.setState({ Status: "Failed" });
          }
        } else {
          this.context.UpdateOrderStatus("Failed");
          this.setState({ Status: "Failed" });
        }
      })
      .catch((error) => {
        console.error("GetProductDataType - There was an error! ", error);
        this.setState({ Status: "Failed" });
      });
  };

  InitializeWithArg = (id) => {
    this.setState({ merchantTransactionId: id }, () => {
      if (this.state.receivedArg == "") {
        this.state.receivedArg = "true";
        this.GetOrdereByTransactionId();
      }
    });
  };

  render() {
    if (this.state.merchantTransactionId == "") {
      return (
        <>
          <CompleteOrderPhonePeArg
            InitializeWithArg={this.InitializeWithArg}
          ></CompleteOrderPhonePeArg>
        </>
      );
    }
    if (
      this.context.OrderStatus !== "" &&
      this.context.storeSettings != "" &&
      // this.context.orderData != "" &&
      this.state.Status != ""
    ) {
      return <Navigate to="/CompleteOrder" />;
    }
    return (
      <>
        {this.state.merchantTransactionId == true ? (
          <>
            {window.innerWidth >= 1200 ? (
              <>
                <CompleteOrderView></CompleteOrderView>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const CompleteOrderPhonePeArg = (props) => {
  const [searchParams] = useSearchParams();
  props.InitializeWithArg(searchParams.get("id"));

  return <div></div>;
};
