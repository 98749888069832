import React, { useState, useContext, Component, createRef } from "react";
import { Container, Row, Button, Col, Nav, Table, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCurrentDateTime } from "../../helper";
import CartContext from "./CartContext";
import { OrderSummary, OrderedItems } from "./CheckoutView";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

export default class CompleteOrderViewMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      intervalId: "",
      timerExpired: false,
      firstLoad: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // if (this.context.portalMode == "Qrcode") {
    //   this.state.intervalId = setInterval(
    //     () => {
    //       this.setState({ timerExpired: true });
    //     },
    //     this.context.storeSettings.kioskInvoicePageWaitTimeInMilliSeconds ==
    //       undefined
    //       ? 10000
    //       : this.context.storeSettings.kioskInvoicePageWaitTimeInMilliSeconds
    //   ); //refresh once in 10secs
    // }
    var d1 = document.getElementById("one");
    if (d1) {
      d1.innerHTML =
        "<html>" +
        this.context.storeSettings.invoiceCompleteOrderMessage +
        "</html>";
    }
  }
  componentWillUnmount() {
    if (
      this.context.OrderStatus === "Success" ||
      this.context.OrderStatus == "Success"
    )
      this.context.ClearCartAndOrderDetails();
  }
  onclickCheckCheckoutLink = () => {
    this.context.UpdateOrderStatus("");
  };
  IamdoneClick = (e) => {
    clearInterval(this.state.intervalId);
    window.close();
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;

    // for Qrcode
    // if (this.state.timerExpired == true) {
    //   clearInterval(this.state.intervalId);
    //   window.close();
    //   //return <Navigate to="/Kiosk" />;
    // }

    if (this.context.OrderStatus === "Dismissed") {
      return (
        <>
          <div
            className="text-start mx-5 "
            style={{
              paddingTop: "65px",
            }}
          >
            <br></br>
            <Button
              as={Link}
              className="text-primary bg-white text-center border-0"
              to="/Checkout"
              onClick={(e) => this.context.UpdateOrderStatus("")}
            >
              <h5>
                &#60;&#60; Order dismissed. Go back to CheckOut page and try
                again!
              </h5>
            </Button>
            <br></br>
            {/* <h2 className="text-danger">
              <span className="blink_me">
                Payment dismissed. Please try again &nbsp;&nbsp;
              </span>
              <Button size="lg" onClick={(e) => this.CallRazorPay()}>
                <b>&nbsp;&nbsp;Pay&nbsp;&nbsp;</b>
              </Button>
            </h2> */}
          </div>
        </>
      );
    }
    if (this.context.OrderStatus === "Failed") {
      return (
        <>
          <div
            className="text-start mx-5"
            style={{
              paddingTop: "65px",
            }}
          >
            <br></br>
            <Button
              as={Link}
              className="text-primary bg-white text-center border-0"
              to="/Checkout"
              onClick={(e) => this.context.UpdateOrderStatus("")}
            >
              <h5>&#60;&#60; Go back to CheckOut page</h5>
            </Button>
            <br></br>
            <h2 className="text-danger">
              <span className="blink_me">
                Order Failed. Please try again! &nbsp;&nbsp;
              </span>
            </h2>
          </div>
        </>
      );
    }

    if (this.context.OrderStatus === "Success") {
      return (
        <>
          <div
            style={{
              paddingTop: "85px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <div className="row mt-1 mx-1 px-0">
              <div className="col-md-2"></div>
              <div className="col-md-8 bg-white mb-5 invoiceDsnSec">
                <Row className="text-center">
                  <Col className=" text-center ">
                    <h1 className="mb-3 mt-3">
                      <b>Invoice</b>
                    </h1>
                  </Col>
                </Row>
                <Row className="text-center  mb-4">
                  <div>
                    <b>{this.context.storeSettings.storeName}</b>
                    <br />
                    {this.context.shippingDetails.address},{" "}
                    {this.context.shippingDetails.city},{" "}
                    {this.context.shippingDetails.state},{" "}
                    {this.context.GetCountryByCode(
                      this.context.shippingDetails.country
                    )}
                    , {this.context.shippingDetails.pincode}{" "}
                  </div>
                  {this.context.storeSettings.gstNumber ? (
                    <>
                      <div>GSTIN: {this.context.storeSettings.gstNumber}</div>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className="">
                  <Col md={12} className="text-start ms-3 text-success">
                    <h5 class="text-dark ">
                      Order No:{" "}
                      <b>
                        {/* {this.context.orderData.orderId.replace(
                    this.context.store.id,
                    ""
                  )} */}
                        {this.context.orderData.orderId}
                      </b>
                    </h5>
                  </Col>
                  <Col md={12} className="text-start ms-3 text-dark">
                    {/* <h3>{this.context.storeSettings.storeName}</h3> */}
                    <h5 class="text-dark ">
                      {" "}
                      Date:
                      {/* {new Date().toLocaleString(
                  this.context.storeSettings.defaultLocale
                )} */}
                      <b>{this.context.GetInDateFormat(new Date())}</b>
                    </h5>
                  </Col>
                </Row>

                <div className="mx-1 px-1 text-start">
                  <br></br>
                  <h6 className="text-success text-center invoiceSuccessMsgDsn mb-3">
                    <span>
                      {/* <img
                  src="./images/smiley.jpg"
                  className="mx-2"
                  alt="..."
                  width="20"
                  height="20"
                /> */}
                      {this.context.portalMode == "Qrcode" ? (
                        <>
                          {
                            this.context.storeSettings
                              .kioskInvoiceCompleteOrderMessage
                          }
                        </>
                      ) : (
                        <>
                          <div id="one"></div>
                          {/* {this.context.storeSettings.invoiceCompleteOrderMessage} */}
                        </>
                      )}
                    </span>
                  </h6>
                  <div className="text-center">
                    {this.context.portalMode == "Qrcode" ? (
                      <>
                        <Button
                          size="lg"
                          onClick={(e) => this.IamdoneClick(e)}
                          style={{
                            fontFamily: "sans-serif",
                            backgroundColor:
                              this.context.theme.mainNavBarBackgroundColor,
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        >
                          <h1>
                            <b>I'm done!</b>
                          </h1>
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <></>
                  {/* Bank Receipt */}
                  <div className="border paymentDetailDsn">
                    {this.context.orderData.paymentModeId == 9 ? (
                      <>
                        {
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                this.context.storeSettings
                                  .bankReceiptDescriptionHtml,
                            }}
                          />
                        }
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <CustomerOrderSummaryMobile
                    order={this.context.orderData}
                  ></CustomerOrderSummaryMobile>
                  <h4 className=" text-center text-dark mt-4 mb-3">
                    <strong>Ordered Items</strong>
                  </h4>
                  <CustomerOrderedItemsMobile
                    orderedItems={this.context.orderData.customerOrderedItems}
                  ></CustomerOrderedItemsMobile>
                  <br></br>
                  <br></br>
                  <CustomerDetailsMobile></CustomerDetailsMobile>
                  <br></br>
                  <br></br>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (this.state.OrderStatus === "Failed") {
      return (
        <>
          <h1> Payment Failed. Please try again</h1>
        </>
      );
    }
  }
}

export class CustomerOrderSummaryMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  // GetTotalSavings = () => {
  //   var totsav = 0;
  //   this.props.order.customerOrderedItems.map((item) => {
  //     totsav =
  //       totsav +
  //       Math.round(
  //         (Number(item.mrp) * Number(item.qty) * Number(item.discount)) / 100,
  //         0
  //       );
  //   });
  //   return totsav;
  // };

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Order Summary */}
        <Row border-dark>
          <h4 className=" text-center text-dark mt-4 mb-3">
            <strong>Order Summary</strong>
          </h4>
        </Row>
        <div className="mx-1 px-1 border ">
          <Container className="px-0 bg-white">
            <br></br>
            <Row className="py-1 ">
              <Col className=" text-start">
                <Form.Label size="" className="">
                  <b>
                    Subtotal [
                    {this.context.getTotalQtyInCart(
                      this.props.order.customerOrderedItems
                    )}{" "}
                    Items]:
                  </b>
                </Form.Label>
              </Col>
              <Col xs={3} className="text-end">
                <Form.Label size="" className="">
                  <h5>
                    {this.props.order.subTotalAmt.toLocaleString(
                      this.context.storeSettings.defaultLocale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: this.context.storeSettings.defaultCurrency,
                      }
                    )}
                  </h5>
                </Form.Label>
              </Col>
            </Row>
            <Row className="py-1">
              <Col className=" text-start">
                <Form.Label size="" className="">
                  <b>Delivery/Shipping charges :</b>
                </Form.Label>
              </Col>
              <Col xs={3} className="text-end">
                <Form.Label size="" className="">
                  <h5>
                    {Math.round(this.props.order.deliveryAmt, 0).toLocaleString(
                      this.context.storeSettings.defaultLocale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: this.context.storeSettings.defaultCurrency,
                      }
                    )}
                  </h5>
                </Form.Label>
              </Col>
            </Row>
            {this.props.order.couponValue > 0 ? (
              <>
                {this.context.storeSettings.hideCouponCodeField == true ? (
                  ""
                ) : (
                  <>
                    {this.props.order.couponValue > 0 ? (
                      <>
                        <Row className="py-1 ">
                          <Col className=" text-start">
                            <Form.Label size="" className="">
                              <b>Coupon Code:</b>
                            </Form.Label>
                          </Col>
                          <Col xs={3} className="text-end">
                            {this.props.order.couponCode}
                          </Col>
                        </Row>
                        <Row className="py-1">
                          <Col className=" text-start">
                            <Form.Label size="" className="">
                              <b>Coupon Code value :</b>
                            </Form.Label>
                          </Col>
                          <Col xs={3} className="text-end">
                            <Form.Label size="" className="">
                              <h5>
                                {this.props.order.couponValue.toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </h5>
                            </Form.Label>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {this.context.taxDetails.inclusiveOfTax == true ? (
              ""
            ) : (
              <>
                <Row className="py-1">
                  <Col className=" text-start">
                    <Form.Label size="" className="">
                      <b>
                        Tax&nbsp; ({this.context.taxDetails.taxPercentage}
                        %) :
                      </b>
                    </Form.Label>
                  </Col>
                  <Col xs={3} className="text-end">
                    <Form.Label size="" className="">
                      <h5>
                        {this.props.order.taxAmount.toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </h5>
                    </Form.Label>
                  </Col>
                </Row>
              </>
            )}
            <Row className="mtBorDsn">
              <Col className=" text-start">
                <Form.Label size="" className="">
                  <h3 className="my-1">
                    <b> Total :</b>
                  </h3>
                  Total Savings:
                </Form.Label>
              </Col>
              <Col xs={3} className="text-end">
                <Form.Label size="" className="my-1">
                  <h3>
                    <b>
                      {Math.round(this.props.order.totalAmt, 0).toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                    </b>
                  </h3>

                  {this.props.order.totalSavings.toLocaleString(
                    this.context.storeSettings.defaultLocale,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    }
                  )}
                </Form.Label>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export class CustomerOrderedItemsMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className=" bg-white  ">
          <div className="">
            <div className="row ">
              <div className="px-3 ">
                <Table
                  className="cartviewtableOnCheckout px-5 border"
                  responsive="md"
                >
                  <thead>
                    <tr className="bg-light text-center">
                      <th className=""></th>
                      <th>Price</th>
                      <th>Disc</th>
                      <th>Qty</th>
                      <th>Sub.Total</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {this.props.orderedItems.map((ordereditem) => (
                      <>
                        <tr className="text-center align-middle">
                          <td className="col-md-3 ">
                            <img
                              src={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/images/" +
                                ordereditem.imgFileName +
                                this.context.store.storageSasToken
                              }
                              // className="PrdImageOnCartViewMobile mx-auto"
                              alt="..."
                              width="55"
                              height="55"
                            />
                            <br></br>
                            <small>
                              <b>{ordereditem.productName}</b>
                            </small>
                          </td>
                          <td className="col-md-2 ">
                            {ordereditem.mrp.toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </td>
                          <td className="col-md-2 ">{ordereditem.discount}%</td>
                          <td className="col-md-2 ">{ordereditem.qty}</td>
                          <td className="col-md-2">
                            <b>
                              {Math.round(
                                ordereditem.qty *
                                  (ordereditem.mrp -
                                    (ordereditem.mrp * ordereditem.discount) /
                                      100),
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </b>
                            <br />

                            {Math.round(
                              ordereditem.qty *
                                ((ordereditem.mrp * ordereditem.discount) /
                                  100),
                              0
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export class CustomerDetailsMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="border ">
          <div>
            <Container className="bg-white my-3">
              <Row border>
                <h3 className=" text-dark mb-4">
                  <strong>Customer Information</strong>
                </h3>
              </Row>
              <Row>
                <div className="col-md-6 ">
                  <h5 className=" text-start text-dark py-2">
                    <strong>Delivery address</strong>
                  </h5>
                  <>
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .firstName
                    }
                    &nbsp;{" "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .lastName
                    }
                    <br></br>
                    Phone No :{" "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .mobileNo
                    }
                    <br></br>
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .address
                    }
                    <br></br>
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .city
                    }
                    ,{" "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .state
                    }
                    ,{" "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .country
                    }
                    {" - "}
                    {
                      this.context.orderData.customerOrderDeliveryAddresses[0]
                        .pinCode
                    }
                  </>
                  <br />
                  <h5 className=" text-start text-dark">
                    <strong>Delivery Note:</strong>
                    {this.context.shippingDetails.deliveryDuration}
                  </h5>
                </div>
                {this.context.userData.customerUserTypeId == 1 ? (
                  <>
                    <div className="col-md-6 ">
                      <br></br>
                      <h5 className=" text-start text-dark">
                        <strong>Billing address</strong>
                      </h5>
                      <>
                        {this.context.userData.firstName}
                        &nbsp; {this.context.userData.lastName}
                        <br></br>
                        Phone No : {this.context.userData.mobileNo}
                        <br></br>
                        {this.context.userData.address}
                        <br></br>
                        {this.context.userData.city},{" "}
                        {this.context.userData.state},{" "}
                        {this.context.userData.country}
                        {" - "}
                        {this.context.userData.pinCode}{" "}
                      </>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Row>
            </Container>
          </div>
        </div>
        <br></br>
        <br></br>
      </>
    );
  }
}

export class CompleteOrderQrcodePhonePe extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.context.SetPortalMode("Qrcode");
  };
  render() {
    return (
      <>
        <CompleteOrderPhonePe></CompleteOrderPhonePe>
      </>
    );
  }
}

export class CompleteOrderPhonePe extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      // maincategorynamearg: "",
      // maincategorynameargloaded: false,
      merchantTransactionId: "",
      Status: "",
      receivedArg: "",
    };
  }

  GetOrdereByTransactionId = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetOrdereByPhonePeTransactionId/" +
          this.state.merchantTransactionId
      )
      .then((response) => {
        if (response.status === 200) {
          this.context.storeOrderInformation(response.data);
          this.context.UpdateOrderStatus("Success");
          this.setState({ Status: "Success" });
        } else {
          this.setState({ Status: "Failed" });
        }
      })
      .catch((error) => {
        console.error("GetProductDataType - There was an error! ", error);
        this.setState({ Status: "Failed" });
      });
  };

  InitializeWithArg = (id) => {
    this.setState({ merchantTransactionId: id }, () => {
      if (this.state.receivedArg == "") {
        this.state.receivedArg = "true";
        this.GetOrdereByTransactionId();
      }
    });
  };

  render() {
    if (this.state.merchantTransactionId == "") {
      return (
        <>
          <CompleteOrderPhonePeArg
            InitializeWithArg={this.InitializeWithArg}
          ></CompleteOrderPhonePeArg>
        </>
      );
    }
    if (
      this.context.OrderStatus !== "" &&
      this.context.storeSettings != "" &&
      // this.context.orderData != "" &&
      this.state.Status != ""
    ) {
      return <Navigate to="/CompleteOrder" />;
    }
    return (
      <>
        {this.state.merchantTransactionId == true ? (
          <>
            <CompleteOrderViewMobile></CompleteOrderViewMobile>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const CompleteOrderPhonePeArg = (props) => {
  const [searchParams] = useSearchParams();
  props.InitializeWithArg(searchParams.get("id"));

  return <div></div>;
};
